<template>
  <div :style="getElementStyle">
    <!-- Below code is for Before Field View -->

    <div v-if="!isDefalutPos">
      <div style="display: flex; align-items: unset">
        <label for="text" style="white-space: nowrap; margin-right: 5px">{{
          data.label
        }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold; margin-right: 5px"
        >
          *
        </span>
        <span v-if="data.description" style="margin-right: 5px">
          <i class="el-icon-info" :title="data.description"></i>
        </span>
        <span>
          <i
            class="el-icon-copy-document"
            v-if="!isDataTableField"
            @click="copyDocument"
          ></i
        ></span>

        <input
          :style="{ 'text-align': inputTextAlignment, ...getStyle }"
          :placeholder="data.placeholder"
          v-model="selectedValue"
          :suffix-icon="data.properties.el_icon"
          class="nfv"
        />
      </div>
      <div v-if="isActive || isDataTableField" class="setting-icon">
        <i class="el-icon-s-tools" @click="openSettings"></i>
      </div>
    </div>
    <!-- Below code is for Top Field View -->

    <el-row align="middle" v-else>
      <el-col
        v-if="!isInDataTable"
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        ref="colRef"
        :style="computedStyles"
      >
        <label for="text">{{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <i
          class="el-icon-copy-document"
          v-if="!isDataTableField"
          @click="copyDocument"
        ></i>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <!-- <el-col :span="showLabel && isDefalutPos ? 4 : 0">
        <span v-if="data.description">
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </span>
      </el-col> -->
      <el-row v-if="isActive || isDataTableField" class="setting-icon">
        <i class="el-icon-s-tools" @click="openSettings"></i>
      </el-row>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <div>
          <input
            id="numberInput"
            :style="{ 'text-align': inputTextAlignment, ...getStyle }"
            :placeholder="data.placeholder"
            v-model="selectedValue"
            :suffix-icon="data.properties.el_icon"
            class="nfv"
          />
        </div>
      </el-col>
    </el-row>
    <!--<div :style="getElementStyle">
      {{data.label}}
      <span v-if="data.description">
        <i class="el-icon-info" :title="data.description" />
      </span>

      <span v-if="isActive || isDataTableField" class="setting-icon" @click="openSettings">
        <i class="el-icon-s-tools" />
      </span>
    </div>
    <div>
      <el-input :style="getStyle" :placeholder="data.placeholder" v-model="selectedValue"></el-input>
    </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NumberView",
  components: {},
  props: ["data", "value", "isActive", "isDataTableField", "isInDataTable"],
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getStyle() {
      let inputStyle = "";
      if (this.data.styles) {
        inputStyle += this.data.styles.background
          ? `background-color: ${this.data.styles.background};height:${
              this.data.height - 30
            }px`
          : `height:${this.data.height - 30}px`;
        inputStyle += ";";
      }

      return inputStyle;
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
    inputTextAlignment: {
      get() {
        return this.data.styles && this.data.styles.inputTextAlignments
          ? this.data.styles.inputTextAlignments
          : "left"; // Default value if not provided
      },
      set(value) {
        if (this.data.styles) {
          this.data.styles.inputTextAlignments = value;
        } else {
          this.$set(this.data, "styles", { inputTextAlignments: value });
        }
      },
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      validations: [],
      showLabel: true,
    };
  },
  mounted() {
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      // if (this.data.styles.labelStyle == "right") {
      //   this.isDefalutPos = false;
      // }
    }
  },
  methods: {
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "NUMBER") {
          this.isList = true;
          this.data.selectedValue = this.getGlobalVariableById.value;
          this.data.is_list = true;
        } else {
          this.data.selectedValue = this.getGlobalVariableById.value;
          this.data.is_list = false;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    },
  },
  watch: {
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.inputTextAlignment = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
  },
};
</script>

<style lang="scss">
#numberInput input[type="text"] {
  // background-color: aqua;
}

.nfv {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: inherit;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  &:hover {
    border-color: #409eff; /* Change the border color on hover */
  }
  &:focus {
    border-color: #409eff; /* Change the border color when focused */
  }
  &::placeholder {
    color: #ccc; /* Use your desired lighter gray color here */
  }
}
</style>
